import React, { useEffect, useState, Suspense } from "react";
import "./i18n";
import "aos/dist/aos.css";
import i18n from "./i18n";
import { Skeleton, Spin } from "antd";
import ReactGA, { set } from 'react-ga';
import './App.css'
import ErrorBoundary from "./compoents/ErrorBoundary";
import useLang from "./compoents/useLanguage";
import { check_auth } from "./helper";
import useToken from "./compoents/useToken";
import axios from "axios";
import { Footer } from "./compoents/Common/footer/Footer";
import { Page404 } from "./compoents/404";



import { BrowserRouter as Router, Routes,  Route, useLocation, useParams } from "react-router-dom"

import Header from './compoents/Common/header/Header';

import { Home } from './compoents/Home/Home';
import { Login } from "./compoents/login/login";
import { ResetPassword } from "./compoents/resetPassword/resetPassword";
import { ChangePassword } from "./compoents/user/changePassword";

function App() {
  const { lang, setLanguage } = useLang();
  const { token, setToken } = useToken();




ReactGA.initialize('UA-000000-01');
ReactGA.pageview(window.location.pathname + window.location.search);
i18n.changeLanguage(lang);

  const [data, setData] = React.useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [userData, setUserData] = useState(null);
  const [lat , setLat]= useState(null);
  const [lng , setLng]= useState(null);
  
  

  const headers = {
    'Content-Type': 'application/json',
  }
  useEffect(() => {
  
   
    (async () => {



      
      if(token !== null){
        const user = await check_auth(token);
      
        if(user.success === true){
          setUserData(JSON.stringify(user.data));
        }
      }
      
      
    })();
    axios.get("https://api.doxx.care/api/configs?locale="+ localStorage.getItem('lang'), {
			headers: headers
		})
			.then(function (response) {
        console.log(response.data.data);
				setData(response.data.data);
        setDataLoaded(true);
    })
			.catch(function (error) {
				// handle error
				console.log(error);
        setDataLoaded(false);


			});
  
  }, []);

  return  (
  // <React.StrictMode>
  // <React.Fragment>
  <ErrorBoundary>

    {dataLoaded ?  
     <>
  

 
     <div class="main-wrapper">
    <Router>
       <Header userData={userData}  token={token} setLanguage={setLanguage}  phoneD={data.customerSupportInfo.doctorPhoneNumber?data.customerSupportInfo.doctorPhoneNumber:''} phoneP={data.customerSupportInfo.patientPhoneNumber?data.customerSupportInfo.patientPhoneNumber : ''}  email={data.config.patient.customerSupportInfo.email}/>

       <Routes>
       <Route  path='/' exact element={<Home data={data} token={token} userData={userData} />} />

       <Route  path='/login' exact element={<Login data={data} token={token} setToken={setToken}  userData={userData} />} />
       <Route  path='/reset-password' exact element={<ResetPassword data={data} token={token} setToken={setToken}  userData={userData} />} />
       <Route  path='/profile' exact element={<ChangePassword data={data} token={token} setToken={setToken}  userData={userData} />} />

      {/*    <Route exact path='/blog' component={Blog} />
         <Route exact path='/pricing' component={Pricing} />
         <Route exact path='/contact' component={Contact} />  */}
       </Routes>
       {/* <Tip image={data.config.adminConfig[0].attributes.icon.data.attributes.formats.medium.url} 
       height={data.config.adminConfig[0].attributes.icon.data.attributes.height} 
        width={data.config.adminConfig[0].attributes.icon.data.attributes.width}/> */}

       <Footer  />
     
     </Router>
     </div>
    </>
  
  :  <>
<Skeleton size={'large'} active />
      </>}
      </ErrorBoundary>

    // </React.Fragment>
    // </React.StrictMode>
  )
}
export default App;
