import * as CONSTANTS from './CONSTANTS';

export const  check_auth = async (token)=>{
          try {

            let response = await  fetch(CONSTANTS.DEFAULT_URL+'users/me', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json' ,
                'Authorization': 'Bearer '+token

              },
            })
            let json = await response.json();
        
                return { success: true, data: json };
        
            
        
        } catch (error) {
            return { success: false };
          }
}

export const  getUserLocation = async (token)=>{
  
    try {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
              const userLocation = {            // ADDED
                lat: position.coords.latitude,  // ADDED
                lng: position.coords.longitude, // ADDED
              };         
              return userLocation;     
              // ADDED
            });
          } else {
            // code for legacy browsers
            return false;
          }
     
  
  
      
  
  } catch (error) {
      return false;
    }
}