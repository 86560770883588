import axios from 'axios';
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as CONSTANTS from '../../CONSTANTS'
import { Button, message  ,Skeleton} from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { ProfileElement } from './ProfileElement';
import 'antd/dist/antd.css'

export const Home = ({token}) => {
	const tableRef = useRef(null);
	const [key , setKey] = useState(1);
	const [currentPage, setCurrentPage] = useState('');

	const {t} = useTranslation('home');
    const [load , setLoading] = useState(true);
    const [appointmentsLoad , setAppointmentsLoading] = useState(false);

	const [plans , setPlans] = useState([]);

    const [appointments , setAppointmentsData] = useState([]);
	const updatePage = title => {
		setLoading(true);

		setCurrentPage(title)

		setAppointmentsLoading(true);
		setLoading(false);
	 }
    const navigate = useNavigate();
    useEffect(() => {
setLoading(true);
        if(token != null ){
            setLoading(true);
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
            axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
                headers: headers
              })
    .then(function (response) {
      // handle success
    //   http://3.70.182.78:1337/api/orders?filters[patient][id]=1007
	
	setLoading(true);

    axios.get(CONSTANTS.DEFAULT_URL+'patients?populate[syndicate]=*&populate[profile][populate]=user&populate[profile]=*&populate[card_image]=*&filters[$and][0][syndicate][name][$notNull]=true&filters[$and][1][profile][user][supscription_plan][id][$null]=true&filters[$and][0][plan_status][$ne]=failed' , {
        headers: headers
      }).then(function (appointmentData){

            setAppointmentsData(appointmentData.data.data);

            setAppointmentsLoading(true);
		setLoading(false);
      })


        

    })
    .catch(function (error) {
        navigate('/login');

    setLoading(true);

    if(error.response.status === 403){
        navigate('/login');


    }
      console.log(error);
    })
    // .finally(function () {
    //     setLoading(false);
    // });

	// http://3.70.182.78:1337/api/plans
	axios.get(CONSTANTS.DEFAULT_URL+'supscription-plans' , {
        headers: headers
      }).then(function (plansData){
		setPlans(plansData.data.data);
		setLoading(false);
      })
}else{
    navigate('/login');

    setLoading(true);

}

}, [currentPage]);




  return (

         <div className='container-fluid'>

  <Suspense>
{load === false ? (<><div class="breadcrumb-bar">
				<div class="container-fluid">
					<div class="row align-items-center">
						<div class="col-md-12 col-12">
							<nav aria-label="breadcrumb" class="page-breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">{t('Home')} </a></li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
            <div class="content">
				<div class="container-fluid">
					<div class="row">
					
						
						<div class="col-md-12">
						<DownloadTableExcel
                    filename="users table"
                    sheet="users"
                    currentTableRef={tableRef.current}
                >

                   <button class="btn btn-success"> {t('Export excel')} </button>

                </DownloadTableExcel>
						
					<div  class="tab-pane fade show active">
								<div class="card card-table mb-0">
									<div class="card-body">
										<div class="table-responsive">
											<table class="table table-hover table-center mb-0" ref={tableRef}> 
												<thead>
													<tr>
														<th>{t('Name')}</th>
														<th>{t('Email')}</th>
														<th>{t('Mobile Number')}</th>
														<th>{t('Registration Date')}</th>

														<th>{t('Name On The Card')}</th>
														<th>{t('Card Image')}</th>
														<th>{t('Card Expire Date')}</th>
														<th>{t('Status')}</th>

														<th>{t('Cofirmed')}</th>
														<th>{t('blocked')}</th>
														<th>{t('Trail')}</th>

														
														<th></th>
													</tr>
												</thead>
												<tbody>
											
				{appointmentsLoad ? (
appointments.length > 0 ? (

	appointments.map((profile, i) => {
	 
	  return (
		profile.attributes.profile.data.attributes.user.data != null ? <>	
		<ProfileElement profileD={profile} updatePage={updatePage}  token={token} plans={plans} key={key} setKey={setKey} />
			
	</>: <></>
				
	   )
	}
	)
	) : (
	  <>No Data</>
	)
  ) : (
	<>
	<Skeleton  avatar
paragraph={{
rows: 4,
}} active />
   </>
  )}
					
									
					</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						
				
							</div>	
							
					</div>
				</div>

			</div></>) : (<Skeleton active />)}

	  </Suspense>
		</div>

  )
}
