import { Button, message, Modal } from 'antd'
import axios from 'axios';
import React, { useState ,useEffect} from 'react'
import * as CONSTANTS from '../../CONSTANTS'

export const ProfileElement = ({ profileD, token ,plans , key  , setKey , updatePage}) => {
  const [profile, setProfile] = useState(profileD)

  const [isModalOpen, setIsModalOpen] = useState(false);
    const refresh = () => window.location.reload(true)

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [syndicate, setUserSendicate] = useState(profile.attributes.syndicate.data.attributes.name)
  const [full_name, setFullName] = useState(profile.attributes.full_name)
  const [expireDate, setExpireDate] = useState(profile.attributes.card_expire_date)
  const [plan_id, setPlanId] = useState(profile.attributes.user)
	const [key2 , setKey2] = useState(1);
  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  const [user_id, setUserId] = useState(profile.attributes.profile.data.attributes.user.data.id)
  const getImage = (width, height) => {
    if (profile.attributes.card_image.data !== null) {
      return <img style={{ "width": width, "height": height }} src={profile.attributes.card_image.data.attributes.url} />
    }
    return "Don't Upload Card Yet"
  }
  const update_name = async () => {
    try {
      let response = await fetch(CONSTANTS.DEFAULT_URL + 'plan/update_user_plan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({'card_expire_date': expireDate, 'full_name': full_name, 'user_id': user_id })
      })
      let json = await response.json();
      if (response.status === 200) {
        console.log(json);
        if(json.odoo_response !== null){
          if(json.odoo_response.result.success.message === "NOT_EXSITS"){
            message.error("Not A DOCTOR ");

          }else{
            message.success("Valid Doctor Name ");
            


          }

        }else{
          message.success("Updated Successfully");

        }
      } else {
        message.error("Failed");

      }
      await sleep(1000);
      refresh();




    } catch (error) {
      console.log(error);
      message.error("Failed");
    }
  }
  const update_plan = async () => {
    try {
      let response = await fetch(CONSTANTS.DEFAULT_URL + 'plan/update_user_plan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ 'plan': 'accept', 'user_id': user_id })
      })
      let json = await response.json();
      if (response.status === 200) {
        message.success("Updated Successfully");
      } else {
        message.error("Failed");

      }
      setKey(Math.random());

      setKey2(Math.random());

      return { success: false };


    } catch (error) {
      message.error("Failed");
    }
  }

  const update_expire_date = async () => {
    try {
      let response = await fetch(CONSTANTS.DEFAULT_URL + 'plan/update_user_plan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ 'card_expire_date': expireDate, 'user_id': user_id })
      })
      let json = await response.json();
      if (response.status === 200) {
        message.success("Updated Successfully");
      } else {
        message.error("Failed");

      }

      await sleep(1000);
      refresh();

    } catch (error) {
      message.error("Failed");
    }
  }
  const update_plan_status = async (status) => {
    try {
      let response = await fetch(CONSTANTS.DEFAULT_URL + 'plan/update_user_plan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ 'plan_status': status, 'user_id': user_id })
      })
      let json = await response.json();
      if (response.status === 200) {
        message.success("Updated Successfully");
      } else {
        message.error("Failed");

      }

      await sleep(1000);
      // refresh();



    } catch (error) {
      message.error("Failed");
    }
  }
  useEffect(() => {
    
  }, [])
  
  return (
    <>

      <tr onClick={showModal}>
        <td>{profile.attributes.profile.data.attributes.firstname + ' ' + profile.attributes.profile.data.attributes.lastname}</td>
        <td>{profile.attributes.profile.data.attributes.user?.data.attributes.email}</td>
        <td>{profile.attributes.profile.data.attributes.user?.data.attributes.mobileNumber}</td>
        <td>{profile.attributes.profile.data.attributes.user?.data.attributes.reg_date}</td>

        <td>
        {full_name}
       

        </td>
        <td >
        <div className='col-md-12'>

          {getImage("150px", "100px")}
          </div>
        </td>

        <td>
          
        {expireDate}
        </td>
        <td>
          
          {profile.attributes.plan_status}
          </td>
        <td>{profile.attributes.profile.data.attributes.user?.data.attributes.confirmed === true ? (<>Yes</>) : (<>No</>)}</td>
        <td>{profile.attributes.profile.data.attributes.user?.data.attributes.blocked === true ? (<>Yes</>) : (<>No</>)}</td>
        <td>{profile.attributes.profile.data.attributes.user?.data.attributes.trail}</td>

        <td>
        <h4 className='text-primary'>Requested Syndicate {syndicate}</h4>

          {/* <button className='btn btn-primary' onClick={()=>{update_plan_status("hold")}}>Hold</button> */}
         {full_name !== null && full_name !== undefined && full_name !== '' && expireDate !== null && expireDate !== undefined && expireDate !== '' ? (<button className='btn btn-success' onClick={()=>{update_plan_status("accepted")}}>accepted</button>) : (<></>)} 
          <button className='btn btn-danger' onClick={()=>{update_plan_status("failed")}}>Failed</button>

          </td>

      </tr>
      <Modal title="CARD IMAGE" open={isModalOpen} onOk={update_name} onCancel={handleCancel}>
        {getImage('500', '300px')}
        <div className='col-md-12 row' style={{'padding':'10px'}}>
            <div className='col-md-10'>
              <label>Expire Date</label>
              <input className='form-control' type={'date'} onChange={(e) => { setExpireDate(e.target.value) }} value={expireDate} />

            </div>
            {/* <div className='col-md-2'>

              <button className='btn btn-success' onClick={() => { update_expire_date() }}>Update</button>
            </div> */}
          </div>


          <div className='col-md-12 row' style={{'padding':'10px'}}>
            <div className='col-md-10'>
            <label>Full name</label>

              <input className='form-control' type={'text'} onChange={(e) => { setFullName(e.target.value) }} value={full_name} />

            </div>
       
          </div>


          <div className='col-md-12 row' style={{'padding':'10px'}}>
            <div className='col-md-8'>
              {/* <select className='form-control' onChange={(e)=>{setPlanId(e.target.value)}}>
                  {
plans.length > 0 ? (

	plans.map((plan, i) => {
	 
	  return (
		plan.attributes != null ? <>	
                  <option value={plan.id}>{plan.attributes.name}</option>
			
	</>: <></>
				
	   )
	}
	)
	) : (
	  <>No Doctors</>
	)
  }
              </select> */}

            </div>
       
          </div>
      </Modal>
    </>
  )
}
